<template>
  <v-row align="center" justify="center" class="page-content">
    <v-col align="center" justify="center">
      <transition appear name="logo-fade">
        <v-row align="center" justify="center" class="logo-wrapper">
          <div class="logo">
            <v-img
              alt="Danone Logo"
              class="shrink mr-2"
              contain
              src="@/assets/header/logo-big.svg"
              width="300"
            />
            <div class="text">Sales</div>
          </div>
        </v-row>
      </transition>
      <v-row align="center" justify="center" style="height: 430px" v-if="!showForm">
      </v-row>
      <v-row align="center" justify="center">
        <transition name="form-fade"><LoginForm v-if="showForm" /></transition>
      </v-row> </v-col
  ></v-row>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";

export default {
  name: "Login",
  data() {
    return {
      showForm: false,
    };
  },
  components: {
    LoginForm,
  },
  created() {
    setTimeout(() => (this.showForm = true), 1000);
  },
};
</script>

<style lang="scss" scoped>
.page-content {
  height: calc(100vh - 70px);
}
.login {
  position: relative;
}
// плавное появление logo
.logo-fade-enter-active,
.logo-fade-leave-active {
  transition: transform 1s;
}
.logo-fade-enter {
  transform: translateY(200px);
}
// плавное появление формы логина
.form-fade-enter-active,
.form-fade-leave-active {
  transition: opacity 1s;
}
.form-fade-enter {
  opacity: 0;
}
.logo {
  &-wrapper {
    height: 180px;
    padding-bottom: 75px;
    margin-top: 30px;
  }
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    font-weight: 200;
    font-size: 20px;
    line-height: 23px;
  }
}
</style>
