<template>
  <v-app>
    <v-app-bar app color="white" dark height="70" class="header" v-show="$route.name !== 'Login'">
      <router-link class="d-flex align-center" :to="{ name: 'Customers' }">
        <v-img
          alt="Danone Logo"
          class="shrink mr-2"
          contain
          src="@/assets/header/logo.svg"
          width="155"
        />
      </router-link>

      <v-spacer></v-spacer>
      <!-- пользователи -->
      <v-btn text color="black" v-if="isLoggedIn" :to="{ name: 'Users' }">
        <span>Пользователи</span>
      </v-btn>
      <!-- клиенты -->
      <v-btn
        class="customers-btn"
        text
        color="black"
        v-if="isLoggedIn"
        :to="{ name: 'Customers' }"
      >
        <span>Клиенты</span>
      </v-btn>
      <!-- выход -->
      <v-btn
        v-show="$route.name !== 'Login'"
        class="link"
        text
        color="black"
        @click="isLoggedIn ? logout() : $router.push({ name: 'Login' })"
      >
        <span>{{ isLoggedIn ? "Выйти" : "Войти" }}</span>
      </v-btn>
    </v-app-bar>

    <v-main class="main">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  computed: {
    isLoggedIn() {
      return this.$store.getters["authorization/isLoggedIn"];
    },
  },
  methods: {
    logout() {
      // выход из уч. записи
      this.$store
        .dispatch("authorization/logout")
        .then(() => this.$router.push({ name: "Login" }))
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import url("~@/styles/normalize.scss");
.v-overlay {
  margin-top: 70px;
  &__scrim {
    // opacity: 0 !important;
    background-color: rgba(108, 108, 108, 0.15) !important;
  }
}
.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.12) !important;
  z-index: 200 !important;
  // .link{
  //   text-transform: unset ;
  //   letter-spacing: normal;
  // }
  .customers-btn {
    margin: 0 8px;
    margin-right: 64px;
  }
}
.v-input--switch__thumb {
  color: white !important;
}
// для фиксации заголовков таблицы, еще необходимо добавить class sticky-header заголовкам
.v-data-table .v-data-table__wrapper {
  overflow: unset !important;
  overflow-x: unset !important ;
  overflow-y: unset !important ;
}
body,
.main {
  background: #eff2f5;
}
button {
  outline: none;
}
button:focus {
  outline: none;
}
</style>